import {
    $, addAction, doAction, INIT,
} from '@situation/setdesign.util';

function textNodesUnder(el) {
    let n;
    const a = [];
    const walk = document.createTreeWalker(el, NodeFilter.SHOW_TEXT, null, false);
    // eslint-disable-next-line no-cond-assign
    while (n = walk.nextNode()) {
        if (n.nodeType === 3 && n.nodeValue.length > 1) {
            a.push(n);
        }
    }
    return a;
}

addAction(INIT, () => {
    textNodesUnder($('main')[0]).forEach((el) => {
        const u = el.nodeValue;
        const reg = /\b(\d+)\b/g;
        const replacement = u.replace(reg, '<span class="numbers">$1</span>');
        if (u !== replacement) {
            $(el).replaceWith(replacement);
        }
    });
    $('.image-bottom-right').each((i, el) => {
        const $el = $(el);
        const $container = $el.parents('.content-section');
        const $bg = $container.children('.content-background-image');
        $bg.append($el.clone());
        setTimeout(() => {
            doAction('refreshLazyLoad', $container);
        }, 50);
    });
});
