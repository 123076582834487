import {
    $, addAction, INIT,
} from '@situation/setdesign.util';

addAction(INIT, () => {
    const selector = '.sub-nav';
    const OPEN_CLASS = 'sub-nav--open';
    const $selector = $(selector);
    const $document = $(document);
    $document.click((event) => {
        const $target = $(event.target);
        if (!$target.closest(selector).length && $selector.is(':visible')) {
            $selector.removeClass(OPEN_CLASS);
        }
    });
    $document.on('keydown', (event) => {
        const KEY = 'Escape';
        if (event.key === KEY && $selector.is(':visible')) {
            $selector.removeClass(OPEN_CLASS);
        }
    });
});
