import { $, addAction, INIT } from '@situation/setdesign.util';

const addIcon = ($btn, name) => $btn.addClass('button--icon')
    .prepend('<span class="button__icon-spacer"></span>')
    .append(`<span class="icon icon-${name}"><svg><use class="icon-use" xlink:href="#icon-${name}"></use></svg></span>`);

addAction(INIT, () => {
    $('.button').each((i, el) => {
        const $btn = $(el);
        const label = $btn.text();
        if (label.toLowerCase().indexOf('donate') >= 0) {
            addIcon($btn, 'heart');
        } else if (label.toLowerCase().indexOf('register') >= 0) {
            addIcon($btn, 'chevron');
        }
    });
});
