import {
    $, addAction, INIT,
} from '@situation/setdesign.util';

addAction(INIT, () => {
    const $eventCaptionsTrigger = $('.event-captions-trigger');
    if (!$eventCaptionsTrigger.length) {
        return;
    }

    const EVENT_CAPTIONS_ID = '#event-captions';
    $eventCaptionsTrigger.each((eventCaptionIndex, eventCaption) => {
        const $this = $(eventCaption);
        const $target = $(EVENT_CAPTIONS_ID);
        const $row = $target.parents('.content-row');
        const CLASS = 'show-event-captions';
        const VIEW = 'View';
        const HIDE = 'Hide';
        $this.on('click', () => {
            const hasClass = $row.hasClass(CLASS);
            $row.toggleClass(CLASS, !hasClass);
            $this.text($this.text().replace(
                !hasClass ? VIEW : HIDE,
                !hasClass ? HIDE : VIEW,
            ));
        });
    });
});
