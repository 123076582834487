window.addEventListener('load', () => {
    window.cookieconsent.initialise({
        palette: {
            popup: {
                background: '#000000',
                text: '#fffff',
            },
            button: {
                background: 'rgba(var(--global__colors__primary), 1)',
                text: '#00000',
            },
        },
        content: {
            message: 'We use cookies and similar technologies to give you the best user experience, '
                + 'for analytics and to show you offers tailored to your interests on our site and other sites. '
                + 'Read our <a href="https://www.rescue.org/respecting-your-privacy" target="_blank">Privacy Policy</a> to learn more or to opt-out. '
                + 'Click the button to agree to our use of cookies and similar technologies.',
            dismiss: 'ACCEPT AND CONTINUE',
            // link: 'visit our cookie policy',
            // href: ' https://www.rescue.org/respecting-your-privacy',
        },
    });
});
