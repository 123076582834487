import {
    $, addAction, INIT, LAYOUTEND,
} from '@situation/setdesign.util';

addAction(INIT, () => {
    function highlight() {
        return $('.highlight').filter((index, el) => el.className.indexOf('highlight--') >= 0);
    }
    function wrapLines() {
        $('.highlight__row .highlight__word').unwrap();
        $('.highlight--align').removeClass('highlight--align');

        const groups = SetDesign.groupByRow(highlight().find('.highlight__word'));
        groups.forEach(($els) => {
            $els.parents('.highlight').addClass('highlight--align');
            $els.wrapAll($('<span class="highlight__row">'));
        });
    }
    highlight().each((index, el) => {
        $(el).html((i, html) => html.split(' ').map((word) => `<span class="highlight__word">${word} </span>`).join(''));
    });

    wrapLines();
    addAction(LAYOUTEND, wrapLines);
    $('.highlight').addClass('show');
});
